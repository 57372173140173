@import "./react-datepicker";
@import "./chat-widget";

/** デフォルトの font-family */
$DEFAULT-FONT-FAMILY: "Helvetica Neue", helvetica, arial, sans-serif;

/** PDF 生成画面の font-family */
$PDF-FONT-FAMILY: "Noto Sans CJK JP", $DEFAULT-FONT-FAMILY;

body {
  font-family: $DEFAULT-FONT-FAMILY;
  color: #333; // overwrite user-agent style
}

// PDF 出力時に画面用のスタイルを打ち消す
@media print {
  body {
    font-family: $PDF-FONT-FAMILY;
    color: #000; // 印刷の場合は濃いコントラストの方が良いと思われる
  }
}

@page {
  size: a4;
}
